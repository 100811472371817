import React, { Suspense, useEffect, lazy, useState } from 'react';
import { Spin, Row, ConfigProvider, message } from 'antd';
import itIT from 'antd/es/locale/it_IT';
import { Switch, Route, Redirect } from "react-router-dom";
import { sendRequest } from 'components/utils/axios.config';

import "antd/dist/antd.less";
import "./index.css";

const Home = lazy(() => import('./views/home'));


var first_render = true;

function Routes() {

    var [activeRequests, setActiveRequests] = useState(0);

    if (first_render) {
        /**
         * impostazioni axios
         */
        // Add a request interceptor
        sendRequest.interceptors.request.use(function (config) {
            // Do something before request is sent
            setActiveRequests(activeRequests + 1);
            return config;
        }, function (error) {
            // Do something with request error
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        // Add a response interceptor
        sendRequest.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log(response);
            setActiveRequests(activeRequests - 1);
            return response.data;
        }, function (error) {

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 403) {
                message.error('Non hai i permessi per accedere a questa risorsa');
            }
            if (error.response.status === 404) {
                message.error('404 Url not found');
            }
            if (error.response.status === 400) {
                message.error('Errore durante la richiesta: ' + error.response.data);
            }
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        /**
         * fine impostazioni axios
         */


        first_render = false;
    }


    return (
        <div >
            <ConfigProvider locale={itIT}>
                <Suspense fallback={
                    <Row type="flex" justify="space-around" align="middle" style={{ minHeight: "calc(100vh - 112px)" }}>
                        <Spin />
                    </Row>}>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                    </Switch>
                </Suspense>
            </ConfigProvider>
            <div
                style={{ maxHeight: 'none', height: '100vh', width: '100vw', position: "fixed", zIndex: 9999999, top: '50%', left: '50%', display: activeRequests > 0 ? 'block' : 'none' }}>
                <Spin />
            </div>
        </div>
    );
}


export default Routes;
